import { Grid, Typography, Button } from '@mui/material';
import Dropzone from 'react-dropzone';
import dropLogo from '../../../assets/cloud-upload.png';
import { theme } from '../../../styles/Theme';

const classes = {
  uploadLogo: {
    width: '8rem',
    height: '8rem',
  },
  dropzone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    borderRadius: '15px',
    padding: '1.5rem 2rem 2rem 2rem',
    margin: 0,
    background:
      'linear-gradient(0deg, rgba(221,227,255,1) 0%, rgba(255,255,255,1) 100%)',
   height: '19.6rem', 
  },
};


const DropZone = ({ addNewFile }) => {
  return (
    <Grid container

    >
      <Dropzone
        minSize={0}
        onDrop={(file) => {
          addNewFile(file[0]);
        }}
      >
        {({ getRootProps, getInputProps, isDragRejected }) => (
          <Grid 
            {...getRootProps()}
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
            spacing={1}
            sx={classes.dropzone}
          >
            <Grid item>
              <img
                src={dropLogo}
                style={classes.uploadLogo}
                alt='cloud-logo.svg'
              />
            </Grid>
            <Grid item>
              <Typography>Drag & Drop your files here</Typography>
            </Grid>
            <Grid item>
              <Typography>OR</Typography>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary'>
                Browse files
              </Button>
            </Grid>
            {isDragRejected && <Typography>File type rejected.</Typography>}
          </Grid>
        )}
      </Dropzone>
    </Grid>
  );
};

export default DropZone;
