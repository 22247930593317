import React from 'react';

const PieSegment = ({ startAngle, endAngle, outerRadius, innerRadius, color, length }) => {
    const rad = Math.PI / 180;

    const cx = 155, cy = 155, R = outerRadius, r = innerRadius, A = length-17, a = 8, o = 20;

    // Control points for the quadratic Bézier curves
    const px1 = cx + R * Math.cos(0);
    const py1 = cy + R * Math.sin(0);
    const px2 = cx + R * Math.cos((2 * a + A) * rad);
    const py2 = cy + R * Math.sin((2 * a + A) * rad);
    const px3 = cx + r * Math.cos((2 * a + A) * rad);
    const py3 = cy + r * Math.sin((2 * a + A) * rad);
    const px4 = cx + r * Math.cos(0);
    const py4 = cy + r * Math.sin(0);

    // Points used to draw the shape
    const points = {
        x11: cx + (R - o) * Math.cos(0),
        y11: cy + (R - o) * Math.sin(0),
        x1: cx + R * Math.cos(a * rad),
        y1: cy + R * Math.sin(a * rad),
        x2: cx + R * Math.cos((a + A) * rad),
        y2: cy + R * Math.sin((a + A) * rad),
        x21: cx + (R - o) * Math.cos((2 * a + A) * rad),
        y21: cy + (R - o) * Math.sin((2 * a + A) * rad),
        x31: cx + (r + o) * Math.cos((2 * a + A) * rad),
        y31: cy + (r + o) * Math.sin((2 * a + A) * rad),
        x3: cx + r * Math.cos((a + A) * rad),
        y3: cy + r * Math.sin((a + A) * rad),
        x4: cx + r * Math.cos(a * rad),
        y4: cy + r * Math.sin(a * rad),
        x41: cx + (r + o) * Math.cos(0),
        y41: cy + (r + o) * Math.sin(0)
    };

    // Rounded corners with quadratic Bézier curves
    const d = `M${points.x1},${points.y1} 
                A${R},${R},0 ${(endAngle - startAngle) > 180 ? 1 : 0},1 ${points.x2},${points.y2}
                Q${px2},${py2} ${points.x21},${points.y21} 
                L${points.x31},${points.y31}
                Q${px3},${py3} ${points.x3},${points.y3}
                A${r},${r},0 ${(endAngle - startAngle) > 180 ? 1 : 0},0 ${points.x4},${points.y4}
                Q${px4},${py4} ${points.x41},${points.y41}
                L${points.x11},${points.y11}
                Q${px1},${py1} ${points.x1},${points.y1}Z`;

    const midpointAngle = (startAngle + endAngle-length) / 2;
    const transform = `rotate(${midpointAngle - 90} ${cx} ${cy})`;

    return (
        <svg viewBox={`${cx - R} ${cy - R} ${2 * R} ${2 * R}`} style={{ border: '1px solid', maxWidth: '90vh' }}>
            <g id="testG" transform={transform}>
                <path id="test" d={d} stroke="white" fill={color} />
            </g>
        </svg>
    );
}

export default PieSegment;
