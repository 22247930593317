import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';

import { useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { theme } from '../../../styles/Theme';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import axios from 'axios';
import affinity_logo from '../../../assets/affinity_logo.png';
import fakeData from './fakeData';
import EnhancedPiechartWithLines from './Piechart';
import { Box } from '@mui/material';
const classes = {
  list: {
    backgroundColor: "#D1FAE5",
    borderRadius: "10px",
    borderLeft: "5px solid #047857",
    marginTop: "15px",
    marginBottom: "15px",
  },
  list_weakness: {
    backgroundColor: "#FFE4E6",
    borderRadius: "10px",
    borderLeft: "6px solid #BE123C",
    marginTop: "15px",
    marginBottom: "0px",
  },
  results_box: {
    backgroundColor: "white",
    borderRadius: "50px",
    paddingTop: "30px",
    paddingBottom: "15px",
    width: "85%",
    maxWidth: "1150px",
    margin: "0 auto",
    overflowY: "none",
  },
  contentInside: {
    width: "100%",
    maxWidth: "435px",
  },

  closeIconStyling: {
    padding: "4px",
    borderRadius: "100px",
    backgroundColor: "rgba(255, 0, 0, 0.2)",
    cursor: "pointer",
    "&:hover": {
      fill: "#FF5349",
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  affinityGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "250px",
    marginTop: "60px",
    marginLeft: "15px",
  },

  affinityTextField: {
    margin: "0px 10px 0 10px",
    flexGrow: 1,
  },

  affinityBtn: {
    backgroundColor: "white",
    padding: "none",
    borderRadius: "100%",
    border: "transparent",
    width: "60px",
    marginTop: "5px",
    "&:hover": {
      border: "transparent",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("md")]: {
      mt: "15px",
      mb: "15px",
    },
  },

  decisionText: {
    fontWeight: 600,
    fontSize: "28px",
    color: "black",
  },
};



const Result = ({ result }) => {
  const [modalTextInput, setModalTextInput] = useState('');
  const { user_id } = useParams();
  const client_id = Cookies.get('access_token');
  const [affinityError, setAffinityError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dataSentSuccess, setDataSentSuccess] = useState(false);
  const [dataSentError, setDataSentError] = useState(false);

  //const resultData =  {'overall': {'id': 1, 'name': 'PDF_FILE_NAME.pdf', 'overall_score': 3, 'decision': 'Passed!'}, 'details': [['Customer LTV Mentioned?',0], ['Top Uni Referenced?', 0], ['Fintech Component?', 0], ['Milestones Referenced?', 0], ['Economics Referenced?', 0], ['Scaling Plans Mentioned?', 0], ['Large Market Opportunity?', 0], ['Social Media Discussed?', 0], ['MVP frequently mentioned', 0], ['Building Referenced?', 0], ['"Start-Up" Term Mentioned?', 0], ['Overemphasis on Founder Background?',0], ['Efficiency Improvements?', 0], ['ROI Mentioned?', 3], ['Gross Profit/Margin Discussed?', 2], ['Engineering Discussed?', 1], ['Industry Shift?', -4], ['Competitive Pressures', 2], ['Pricing Discussed?', 0], ['"Massive" Market Opportunity?', 0], ['Issues with Traditional Approach?', 0], ['Unit Economics Mentioned?', 0], ['SEIS Mentioned?', 0], ['Product Reduces Time?', 0], ['Loss Prevention?', 0], ['Regulation Discussed?', 0], ['Academic Element?', 0], ['Scientific Element?', 0], ['End-to-End Solution?', 0], ['Transparency Improvements?', 0], ['Structured Approach?', 0], ['Automation Function?', 0], ['Contracts Closed?', 0], ['Optimisation Element?', 0], ['Cheaper than Competitors?', 0], ['Product Improves Speed?', 0]]};

  const segmentColor = result.overall.overall_score < 0 ? "#FF5D1B" : "#A2DD67";
 
  
  let details = result.details
    .filter((item) => item[1] !== 0)
    .sort((a, b) => Math.abs(a[1]) - Math.abs(b[1]));

  let largerHalf = details.slice(0, Math.ceil(details.length / 2));
  let smallerHalf = details.slice(Math.ceil(details.length / 2));
  smallerHalf.reverse();

  let segments = [];
  let index = 0;

  while (largerHalf.length > 0 || smallerHalf.length > 0) {
    if (index % 2 === 0 && largerHalf.length > 0) {
      segments.push({ label: largerHalf[0][0], value: largerHalf[0][1] });
      largerHalf.splice(0, 1);
    } else if (smallerHalf.length > 0) {
      segments.push({ label: smallerHalf[0][0], value: smallerHalf[0][1] });
      smallerHalf.splice(0, 1);
    }
    index++;
  }

  const totalValue = segments.reduce(
    (acc, item) => acc + Math.abs(item.value),
    0
  );
  let startAngle = 0;

  segments = segments.map((segment) => {
    const segmentValue = Math.abs(segment.value);
    const segmentProportion = segmentValue / totalValue;
    const segmentLength = segmentProportion * 360;
    const endAngle = startAngle + segmentLength;
    const midAngle = (startAngle + endAngle) / 2;

    startAngle = endAngle;

    return {
      ...segment,
      midAngle,
    };
  });


  const sendAffinityData = () => {
    setDataSentSuccess(false);
    setDataSentError(false);
    
    if (modalTextInput === '') {
      setAffinityError('*URL input cannot be empty');
      alert('URL input cannot be empty');
    } else {
      if (result) {
        setLoading(true);
        axios
          .post('/api/affinity', {
            url: modalTextInput,
            result: result,
          })
          .then((res) => {
            if (res.status === 200) {
        
              alert('Data sent successfully.');
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
        
            alert('Data sending failed.');
          });
      }
    }
  };




  
   


return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={classes.results_box}
    >
      <div className={classes.contentInside}>
        <div
          style={{
            backgroundColor:
              result.overall.decision === "Passed!" ? "#D1FAE5" : "#FFE4E6",
            borderRadius: "10px",
            borderLeft:
              result.overall.decision === "Passed!"
                ? "5px solid #047857"
                : "6px solid #BE123C",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "-10px",
            textAlign: "center",
            width: "320px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={classes.decisionText}>
            {result.overall.decision}
          </Typography>
        </div>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            margin: "15px 0",
          }}
        ></Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <EnhancedPiechartWithLines
            result={segments}
            scoreColor={segmentColor}
          />
        </Grid>

        <Grid item sx={{ width: "100%" }}></Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          sx={classes.results_box}
          spacing={2}
        >
          <Box sx={classes.affinityGroup}>
            <TextField
              type="url"
              pattern="https?://.+"
              placeholder="Enter Affinity URL"
              value={modalTextInput}
              onChange={(e) => setModalTextInput(e.target.value)}
              inputProps={{
                style: {
                  height: "13px",
                },
              }}
              sx={classes.affinityTextField}
            />
            <Button
              variant="outlined"
              sx={classes.affinityBtn}
              onClick={() => sendAffinityData()}
            >
              <img
                alt="affinity_logo"
                src={affinity_logo}
                style={{
                  width: "auto",
                  height: "46px",
                  cursor: "pointer",
                  marginBottom: "7px",
                }}
              />
            </Button>
          </Box>
        </Grid>
      </div>
    </Grid>
  );
};

export default Result;
