import {
  Alert,
  Button,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../styles/Theme";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import mainLogo from "../../assets/e1logo.svg";
import axios from "axios";
import Cookies from "js-cookie";
import { ArrowBack } from "@mui/icons-material";
import { redirect, useNavigate, useParams } from "react-router-dom";
import Result from "./components/Result";
import affinity_logo from "../../assets/affinity_logo.png";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import fakeData from "./components/fakeData";
import ResultsContext from "../ResultsContext";
import { useContext } from "react";
const classes = {
  page: {
    width: "100%",

    overflow: "auto",
  },

  bold: {
    fontWeight: "bold",
  },
  textAlign: {
    display: "flex",
    justifyContent: "center",
  },
  logoWrapper: {
    paddingLeft: "2%",
    paddingRight: "2%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  loadingAnimationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: "10px 10px 0 0",
      height: "min-content",
      alignSelf: "end",
    },
    padding: "15px",
  },
  form_wrapper: {
    width: "90%",
    mb: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      margin: "0 auto",
    },
    overflowY: "auto",
  },

  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentInside: {
    maxWidth: "420px",
    width: "100%",
  },
};

const ResultsPage = () => {
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const mobileMid = useMediaQuery(theme.breakpoints.down("sm"));
  let navigate = useNavigate();
  const [affinityModal, setAffinityModal] = useState(false);
  const [modalTextInput, setModalTextInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { user_id } = useParams();
  const client_id = Cookies.get("access_token");
  const [error, setError] = useState(null);
  const [warningError, setWarningError] = useState(false);
  const [affinityError, setAffinityError] = useState(null);

  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataError, setLoadingDataError] = useState(null);
  const [results, setResults] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const { folderId, setFolderId } = useContext(ResultsContext);
  const [isRequestOngoing, setIsRequestOngoing] = useState(false);
  // fetch data and store it into [results, setResults]
  // while fetchin, loading animaition is being displayed
  useEffect(() => {
    if (folderId !== null) {
      setIsRequestOngoing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isRequestOngoing && folderId !== null) {
        axios
          .get("/api/results/" + folderId)
          .then((res) => {
            if (res.status === 200) {
              setResults(res.data[0]);
              setIsRequestOngoing(false);
              setLoading(false);
            } else if (res.status === 202) {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [folderId, isRequestOngoing]);

  const handleBack = async () => {
    window.location.href = "/";
  };

  const logoutResults = () => {
    console.log("LOG OUT");
    axios
      .post("/api/logout", {
        headers: {
          Authorization: ``,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        Cookies.remove("access_token");
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={!loading ? { height: "100vh" } : {}}
        // sx={{ ...classes.page, height: '100vh' }}
        sx={classes.page}
      >
        <Grid item xs={12} sx={classes.logoWrapper}>
          <img
            src={mainLogo}
            alt="logo"
            style={{
              width: mobile ? "5rem" : "15rem",
              height: mobile ? "3rem" : "10rem",
              fill: "white",
            }}
          />
          <Button
            variant="outlined"
            sx={{ border: "1px solid white", color: "white" }}
            onClick={() => logoutResults()}
            startIcon={<LogoutOutlinedIcon sx={{ color: "white" }} />}
          >
            Logout
          </Button>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            ml: "2%",
            marginBottom: "50px",
            alignItems: "start",
          }}
        >
          <Button
            startIcon={<ArrowBack />}
            onClick={() => handleBack()}
            sx={{ color: "white" }}
          >
            Score another company
          </Button>
        </Grid>
        {warningError && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              position: "fixed",
              top: 10,
              left: 0,
              right: 0,
              zIndex: 9999,
              margin: "0 auto",
              width: "25%",
              borderRadius: "20px",
              backgroundColor: "#FEDD5",
              color: "#FEDD5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{warningError[0]}</Typography>
          </Alert>
        )}
        {affinityError && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              position: "fixed",
              top: 10,
              left: 0,
              right: 0,
              zIndex: 9999,
              margin: "0 auto",
              width: "30%",
              borderRadius: "20px",
              backgroundColor: "#FEDD5",
              color: "#FEDD5",
            }}
          >
            <Typography>{affinityError}</Typography>
          </Alert>
        )}
        {loading ? (
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60px",
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Grid>
        ) : error ? (
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              position: "fixed",
              top: 10,
              left: 0,
              right: 0,
              zIndex: 9999,
              margin: "0 auto",
              width: "30%",
              borderRadius: "20px",
              backgroundColor: "#FEDD5",
              color: "#FEDD5",
            }}
          >
            <Typography>
              Error while fetching data. Please, try again.
            </Typography>
          </Alert>
        ) : (
          <>
            {warningError && (
              <Alert
                variant="filled"
                severity="warning"
                sx={{
                  position: "fixed",
                  top: 10,
                  left: 0,
                  right: 0,
                  zIndex: 9999,
                  margin: "0 auto",
                  width: "25%",
                  borderRadius: "20px",
                  backgroundColor: "#FEDD5",
                  color: "#FEDD5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{warningError[0]}</Typography>
              </Alert>
            )}

            <Grid
              item
              style={results != null ? { marginBottom: "300px" } : {}}
              sx={classes.form_wrapper}
            >
              {results ? (
                <Result result={results} />
              ) : (
                <Result result={results} />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ResultsPage;
