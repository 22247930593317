import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import File from './file';
import { theme } from '../../../styles/Theme';

const classes = {
  bold: {
    fontWeight: 'bold',
  },
  scrollFiles: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '250px',
    overflow: 'hidden',
    overflowY: 'scroll',
    gap: '8px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100px',
    },
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'ligtgray',
      outline: `1px solid ligtgray`,
    },
  },
  scrollFilesFixed: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '250px',
    gap: '10px',
  },
};
const FileList = ({ files, removeFile, setLoadingFileAnimationDone }) => {
  return (
    <Grid container direction='column' gap='15px'>
      <Grid item>
        <Typography sx={classes.bold}>Uploaded files</Typography>
      </Grid>
      <Grid item>
        <Box
          sx={files.length > 3 ? classes.scrollFiles : classes.scrollFilesFixed}
        >
          {files.length > 0 &&
            files.map((file, index) => {
              return (
                <Grid item key={index} sx={{ width: '100%' }}>
                  <File
                    setLoadingFileAnimationDone={setLoadingFileAnimationDone}
                    file_id={file.file_id}
                    fileName={file.file_name}
                    fileSize={file.fileSize}
                    removeFile={removeFile}
                  />
                </Grid>
              );
            })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FileList;
