import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DropZone from "./components/dropzone";
import FileList from "./components/file-list";
import { v4 as uuidv4 } from "uuid";
import mainLogo from "../../assets/e1logo.svg";
import { theme } from "../../styles/Theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import ResultsContext from "../ResultsContext";
import Login from "../signIn-page/components/Login";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Cookies from "js-cookie";

import { ArrowBack } from "@mui/icons-material";

const classes = {
  page: {
    width: "100wv",
  },
  mainContainer: {
    backgroundColor: `${theme.palette.basic.main}`,
    borderRadius: ".5rem",
    padding: "2rem 3rem 2rem 3rem",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
  },
  bold: {
    fontWeight: "bold",
  },
  textAlign: {
    display: "flex",
    justifyContent: "center",
  },
  logoWrapper: {
    paddingLeft: "2%",
    paddingRight: "2%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  loadingAnimationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    borderRadius: 3,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px 8px 0 0",
      height: "min-content",
      alignSelf: "end",
    },
  },
};

const MainPage = () => {
  const [files, setFiles] = useState([]);
  const [loadingFileAnimationDone, setLoadingFileAnimationDone] =
    useState(false);
  const [error, setError] = useState(false);
  const [sendingDataError, setSendingDataError] = useState("");
  const [sendingData, setSendingData] = useState(false);
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { setFolderId } = useContext(ResultsContext);
  const mobileMid = useMediaQuery(theme.breakpoints.down("sm"));

  // login dialog display
  const [openLoginDialog, setOpenLoginDialog] = useState(true);

  useEffect(() => {
    if (openLoginDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openLoginDialog]);

  const addNewFile = (file) => {
    if (file.type === "application/pdf") {
      console.log("UPLOADED FILE: ", file);
      setError(false);
      const newFile = {
        file: new File([file], "file"),
        file_id: uuidv4(),
        file_name: file.name,
        fileSize: file.size,
      };
      setFiles((currentFiles) => [...currentFiles, newFile]);
    } else {
      setError(true);
    }
  };

  const removeFile = (id) => {
    setLoadingFileAnimationDone(true);
    const filteredFiles = files.filter((file) => file.file_id !== id);
    setFiles(filteredFiles);
  };

  const submitFiles = () => {
    setFolderId(null); // Prevent race condition and multiple GET requests
    setSendingData(true);

    let form_data = new FormData();

    files.map((file, index) =>
      form_data.append(`file_${index}`, file.file, file.file_name)
    );

    axios
      .post("/api/results", form_data)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setFolderId(res.data.folderId);
          setSendingData(false);
          navigate("/results");
          setFiles([]);
        }
      })
      .catch((err) => {
        setSendingDataError(err.message);
        setSendingData(false);
      });
  };

  const logout = () => {
    axios
      .post("/api/logout", {
        headers: {
          Authorization: ``,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("LOGOUT");
        Cookies.remove("access_token");
        window.location.reload(false);
        console.log(res);
        Cookies.remove("access_token");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={classes.page}
      >
        <Grid item xs={12} sx={classes.logoWrapper}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={mainLogo}
              alt="logo"
              style={{
                width: mobile ? "5rem" : "15rem",
                height: mobile ? "3rem" : "10rem",
                fill: "white",
              }}
            />

            <Button
              startIcon={<ArrowBack />}
              sx={{
                color: "white",
                marginTop: "-20px",
              }}
              onClick={() =>
                (window.location.href = "https://www.e1models.co.uk")
              }
            >
              DDVC Dashboard
            </Button>
          </div>
          <Button
            variant="outlined"
            sx={{ border: "1px solid white", color: "white" }}
            onClick={() => logout()}
            startIcon={<LogoutOutlinedIcon sx={{ color: "white" }} />}
          >
            Logout
          </Button>
        </Grid>
        {sendingData ? (
          <Grid
            item
            xs={10}
            sm={10}
            md={8}
            lg={5}
            xl={4}
            sx={classes.loadingAnimationContainer}
          >
            <Typography
              variant="h5"
              sx={{
                color: `${theme.palette.basic.main}`,
                marginBottom: "30px",
              }}
            >
              Processing...
            </Typography>
            <CircularProgress
              size="5rem"
              sx={{ color: `${theme.palette.basic.main}` }}
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={9}
            sm={9}
            md={7.5}
            lg={4.5}
            xl={3.5}
            sx={classes.mainContainer}
            style={{ marginBottom: "50px" }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h5" align="center" sx={classes.bold}>
                  UPLOAD PITCH DECK
                </Typography>
                <Typography variant="h7" align="center" sx={classes.textAlign}>
                  Upload pitch deck you would like to score.
                </Typography>
              </Grid>
              <Grid item>
                <DropZone addNewFile={addNewFile} />
              </Grid>
              {sendingDataError && (
                <Grid item>
                  <Typography sx={{ color: `${theme.palette.status.danger}` }}>
                    {sendingDataError}
                  </Typography>
                </Grid>
              )}
              {error && (
                <Grid item>
                  <Typography sx={{ color: `${theme.palette.status.danger}` }}>
                    Unsuported file type. Please use .pdf extension.
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <FileList
                  files={files}
                  removeFile={removeFile}
                  setLoadingFileAnimationDone={setLoadingFileAnimationDone}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => submitFiles()}
                  variant="contained"
                  color="primary"
                  disabled={!loadingFileAnimationDone || files.length === 0}
                  sx={{ width: "100%", maxWidth: "100%" }}
                >
                  UPLOAD
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openLoginDialog}
        fullScreen={mobileMid}
        disableScrollLock
        PaperProps={{
          sx: classes.dialog,
        }}
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0,0,30,0.4)",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Login setOpenLoginDialog={setOpenLoginDialog} />
      </Dialog>
    </>
  );
};

export default MainPage;
