import CircleIcon from "@mui/icons-material/Circle";
import PieSegment from "./PieSegment";
import React, { useState, useEffect } from "react";

const Piechart = ({ result, scoreColor }) => {
  const total = result.reduce((acc, item) => acc + Math.abs(item.value), 0);
  const score = result.reduce((acc, item) => acc + item.value, 0);
  const scoreC = score < 0 ? "#FF5D1B" : "#A2DD67";
  let startAngle = 0;
  const outerRadius = 160;
  const innerRadius = 78;
  const gap = 2;
  return (
    <div
      style={{
        position: "relative",
        width: "334px",
        height: "334px",
        marginTop: "50px",
      }}
    >
      <CircleIcon
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          fontSize: "324px",
          fill: "white",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          borderRadius: "50%",
        }}
      />
      <svg
        width="310"
        height="310"
        viewBox="0 0 305 310"
        style={{ position: "absolute", top: "7.5px", left: "7.5px" }}
      >
        {result.map((segment, index) => {
          const segmentLength =
            360 * (Math.abs(segment.value) / total) -
            (result.length === 1 ? 0 : gap);

          const endAngle = startAngle + segmentLength;
          const segmentColor = segment.value < 0 ? "#FF5D1B" : "#A2DD67";

          const pieSegmentElement = (
            <PieSegment
              key={index}
              startAngle={startAngle}
              endAngle={endAngle}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              color={segmentColor}
              length={segmentLength}
            />
          );
          startAngle = endAngle + gap;
          return pieSegmentElement;
        })}

        <text
          x="50%"
          y="50%"
          dy="0"
          textAnchor="middle"
          fontWeight="600"
          fontSize="48px"
          fill={scoreC}
        >
          {score > 0 ? "+" : ""}
          {score}
        </text>
        <text
          x="50%"
          y="50%"
          dy="35"
          dx={score > 10 ? +3 : 0}
          textAnchor="middle"
          fontWeight="600"
          fontSize="28px"
          fill="black"
        >
          Score
        </text>
      </svg>
    </div>
  );
};

const PiechartWithLines = ({ result, scoreColor }) => {
  const total = result.reduce((acc, item) => acc + Math.abs(item.value), 0);

  let startAngle = 0;

  const cx = 155,
    cy = 155;
  const outerRadius = 155;
  const offsetX = 323;
  const offsetY = 337;
  const rad = Math.PI / 180;

  const getMaxXEndRight = () => {
    let maxXEnd = 0;
    let startAngle = 0;
    result.forEach((segment) => {
      const segmentLength = 360 * (Math.abs(segment.value) / total);
      const endAngle = startAngle + segmentLength;
      const midAngle = (startAngle + endAngle) / 2;

      let xEnd = 0;

      if (midAngle >= 0 && midAngle <= 60) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      } else if (midAngle > 60 && midAngle < 120) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      } else if (midAngle >= 120 && midAngle < 180) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      }

      if (xEnd > maxXEnd) {
        maxXEnd = xEnd;
      }

      startAngle = endAngle;
    });

    return maxXEnd;
  };

  startAngle = 0;
  const getMinXEndLeft = () => {
    let maxXEnd = 0;
    let startAngle = 0;
    result.forEach((segment) => {
      const segmentLength = 360 * (Math.abs(segment.value) / total);
      const endAngle = startAngle + segmentLength;
      const midAngle = (startAngle + endAngle) / 2;

      let xEnd = 110;

      if (midAngle >= 300 && midAngle <= 360) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 149;
      } else if (midAngle > 240 && midAngle < 300) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 80;
      } else if (midAngle >= 180 && midAngle < 240) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 120;
      }

      if (xEnd < maxXEnd) {
        maxXEnd = xEnd;
      }

      startAngle = endAngle;
    });

    return maxXEnd;
  };

  startAngle = 0;
  var maxXEndRight = getMaxXEndRight() - 60;

  var minXEndLeft = getMinXEndLeft() + 70;

  const calculateYStart = (midAngle) => {
    return offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
  };

  return (
    <div style={{ position: "relative" }}>
      <Piechart result={result} scoreColor={scoreColor} />

      <svg
        width="760"
        height="560"
        style={{ position: "absolute", top: -cy + "px", left: -cx + "px" }}
      >
        {result.map((segment, index) => {
          const segmentLength = 360 * (Math.abs(segment.value) / total);
          const endAngle = startAngle + segmentLength;
          const midAngle = (startAngle + endAngle) / 2;

          const offsetX = 315;
          const offsetY = 333;
          const rad = Math.PI / 180;

          var oldStartAngle = startAngle;
          startAngle = endAngle;

          if (midAngle >= 0 && midAngle <= 60) {
            // top right

            let xStart = offsetX - 4 + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 18 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;

            var flagFirst = 0;
            var flagNextCentreRight = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (nextSegment) {
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);

              if (!previousSegment && Math.abs(nextYStart - yStart) < 35) {
                flagFirst = 1;
              }

              if (previousSegment) {
                let prevMidAngle = previousSegment["midAngle"];
                let prevYStart = calculateYStart(prevMidAngle);

                if (
                  Math.abs(prevYStart - yStart) < 35 &&
                  nextMidAngle > 60 &&
                  nextMidAngle <= 120
                ) {
                  flagNextCentreRight = 1;
                }
              }
            }

            return (
              <svg
                key={index}
                width={lineLength}
                height="35"
                viewBox={`0 0 ${lineLength} 25`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart}
                y={yStart - 30 - flagFirst * 15 + flagNextCentreRight * 10}
              >
                <path
                  d={`M${lineLength} 1.33203H15L0 ${
                    24.0564 + flagFirst * 15 - flagNextCentreRight * 5
                  }`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          if (midAngle >= 300 && midAngle <= 360) {
            //top left

            let xStart = offsetX + 3 + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 15 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            var flagLast = 0;
            var flagLastCentreLeft = 0;
            var flagLastClose = 0;
            var flagMoveDownNextClose = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment) {
              let prevMidAngle = previousSegment["midAngle"];
              let prevYStart = calculateYStart(prevMidAngle);

              if (!nextSegment && Math.abs(prevYStart - yStart) < 36) {
                flagLast = 1;
              }

              if (nextSegment) {
                let nextMidAngle = nextSegment["midAngle"];
                let nextYStart = calculateYStart(nextMidAngle);

                if (
                  prevMidAngle > 240 &&
                  prevMidAngle < 300 &&
                  Math.abs(nextYStart - yStart) < 35
                ) {
                  flagLastCentreLeft = 1;
                }
                let nextNextSegment = result[index + 2];
                if (!nextNextSegment && Math.abs(prevYStart - yStart) < 35) {
                  flagLastClose = 1;
                }

                let prevPrevSegment = result[index - 2];

                let prevPrevMidAngle = prevPrevSegment["midAngle"];
                if (
                  prevPrevSegment &&
                  Math.abs(nextYStart - yStart) < 35 &&
                  prevPrevMidAngle > 240 &&
                  prevPrevMidAngle < 300
                ) {
                  flagMoveDownNextClose = 1;
                }
              }
            }

            return (
              <svg
                key={index}
                width={lineLength}
                height="35"
                viewBox={`0 0 ${lineLength} ${25}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart - lineLength}
                y={
                  yStart -
                  30 +
                  4 -
                  flagLast * 25 +
                  flagLastCentreLeft * 20 -
                  flagLastClose * 5 +
                  flagMoveDownNextClose * !flagLastCentreLeft * 10
                }
              >
                <path
                  d={`M0 ${1.33203}H${
                    lineLength - 40.2585 + flagLast * 10
                  }L${lineLength} ${
                    24.0564 +
                    flagLast * 25 -
                    flagLastCentreLeft * 10 +
                    flagLastClose * 10
                  }`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          if (midAngle > 60 && midAngle <= 120) {
            //centre right

            let xStart = offsetX + 2 + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 18 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;

            return (
              <svg
                key={index}
                width={lineLength}
                height="35"
                viewBox={`0 0 ${lineLength} 25`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart}
                y={yStart - 10}
              >
                <path
                  d={`M${lineLength} 1.5332H15L0 1.5332`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          if (midAngle > 240 && midAngle < 300) {
            //centre left

            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            return (
              <svg
                key={index}
                width={lineLength + 8}
                height="35"
                viewBox={`0 0 ${lineLength} 25`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart - lineLength - 7}
                y={yStart - 2}
              >
                <path
                  d={`M0 1.5332H${lineLength - 35.2251}L${lineLength} 1.5332`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          if (midAngle >= 120 && midAngle < 180) {
            //bottom right
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;

            let newStart = lineLength;

            var flagPrevCentreRight = 0;
            var flagPrevBottomRightClose = 0;
            var flagNextBottomLeft = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment && nextSegment) {
              let previousMidAngle = previousSegment["midAngle"];
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);
              let prevYStart = calculateYStart(previousMidAngle);

              if (
                previousMidAngle >= 60 &&
                previousMidAngle < 120 &&
                nextMidAngle >= 120 &&
                nextMidAngle < 180
              ) {
                flagPrevCentreRight = 1;
              }

              if (
                !(previousMidAngle >= 60 && previousMidAngle < 120) &&
                Math.abs(nextYStart - yStart) < 20
              ) {
                flagPrevBottomRightClose = 1;
              }

              if (
                nextMidAngle >= 180 &&
                nextMidAngle <= 240 &&
                Math.abs(prevYStart - yStart) < 20
              ) {
                flagNextBottomLeft = 1;
              }
            }
            return (
              <svg
                key={index}
                width={lineLength}
                height="35"
                viewBox={`0 0 ${lineLength} ${40}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart - 5}
                y={
                  yStart -
                  5 -
                  flagPrevCentreRight * 5 +
                  flagPrevBottomRightClose * 10 +
                  flagNextBottomLeft * 15
                }
              >
                <path
                  d={`M${lineLength + 20} ${
                    22.0371 +
                    flagPrevBottomRightClose * 10 +
                    flagNextBottomLeft * 7
                  }H${40.4838 - flagNextBottomLeft * 5}L4.38703 1.27173`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          if (midAngle >= 180 && midAngle <= 240) {
            //bottom left
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            var flagPrevBottomRight = 0;
            var flagNextCentreLeft = 0;
            var flagPrevPrevCentreRightNextClose = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment && nextSegment) {
              let previousMidAngle = previousSegment["midAngle"];
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);
              let prevYStart = calculateYStart(previousMidAngle);

              if (
                previousMidAngle >= 120 &&
                previousMidAngle < 180 &&
                Math.abs(nextYStart - yStart) < 35
              ) {
                flagPrevBottomRight = 1;
              }
              if (
                nextMidAngle > 240 &&
                nextMidAngle <= 300 &&
                Math.abs(prevYStart - yStart) < 35
              ) {
                flagNextCentreLeft = 1;
              }
              let prevPrevSegment = result[index - 2];
              if (
                prevPrevSegment &&
                prevPrevSegment["midAngle"] >= 120 &&
                prevPrevSegment["midAngle"] < 180 &&
                previousMidAngle >= 180 &&
                previousMidAngle < 240 &&
                Math.abs(nextYStart - yStart) < 35
              ) {
                flagPrevPrevCentreRightNextClose = 1;
              }
            }
            return (
              <svg
                key={index}
                width={lineLength + flagPrevBottomRight * 7}
                height="35"
                viewBox={`0 0 ${lineLength} ${25}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                x={xStart - lineLength - 5 + flagPrevBottomRight * 4}
                y={
                  yStart -
                  10 +
                  flagPrevBottomRight * 30 -
                  flagNextCentreLeft * 5 +
                  flagPrevPrevCentreRightNextClose * 7
                }
              >
                <path
                  d={`M0 ${22.0371}H${
                    lineLength -
                    20.0971 +
                    flagPrevBottomRight * 5 -
                    flagNextCentreLeft * 5
                  }L${
                    lineLength -
                    flagPrevBottomRight * 5 +
                    flagNextCentreLeft * 10
                  } 3.27173`}
                  stroke="#CDCDCD"
                  strokeWidth="2"
                  strokeDasharray="2 2"
                />
              </svg>
            );
          }

          startAngle = endAngle;
        })}
      </svg>
    </div>
  );
};

const EnhancedPiechartWithLines = ({ result, scoreColor }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const maxCharPerLineWrapper = windowWidth > 1470 ? 35 : 32;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const total = result.reduce((acc, item) => acc + Math.abs(item.value), 0);

  let startAngle = 0;

  const cx = 155,
    cy = 155;
  const outerRadius = 155;
  const offsetX = 323;
  const offsetY = 337;
  const rad = Math.PI / 180;

  const getMaxXEndRight = () => {
    let maxXEnd = 0;
    let startAngle = 0;
    result.forEach((segment) => {
      const segmentLength = 360 * (Math.abs(segment.value) / total);
      const endAngle = startAngle + segmentLength;
      const midAngle = (startAngle + endAngle) / 2;

      let xEnd = 0;

      if (midAngle >= 0 && midAngle <= 60) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      } else if (midAngle > 60 && midAngle < 120) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      } else if (midAngle >= 120 && midAngle < 180) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart + 149;
      }

      if (xEnd > maxXEnd) {
        maxXEnd = xEnd;
      }

      startAngle = endAngle;
    });

    return maxXEnd;
  };

  startAngle = 0;
  const getMinXEndLeft = () => {
    let maxXEnd = 0;
    let startAngle = 0;
    result.forEach((segment) => {
      const segmentLength = 360 * (Math.abs(segment.value) / total);
      const endAngle = startAngle + segmentLength;
      const midAngle = (startAngle + endAngle) / 2;

      let xEnd = 110;

      if (midAngle >= 300 && midAngle <= 360) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 149;
      } else if (midAngle > 240 && midAngle < 300) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 80;
      } else if (midAngle >= 180 && midAngle < 240) {
        let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
        xEnd = xStart - 120;
      }

      if (xEnd < maxXEnd) {
        maxXEnd = xEnd;
      }

      startAngle = endAngle;
    });

    return maxXEnd;
  };

  startAngle = 0;
  var maxXEndRight = getMaxXEndRight() - 40;

  var minXEndLeft = getMinXEndLeft() + 70;
  const calculateYStart = (midAngle) => {
    return offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
  };

  function renderText(x, y, text) {
    const maxCharPerLine = maxCharPerLineWrapper;
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (let word of words) {
      if ((currentLine + word).length <= maxCharPerLine) {
        currentLine += word + " ";
      } else {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      }
    }
    if (currentLine) lines.push(currentLine.trim());

    return lines.map((line, index) => (
      <text x={x} y={y - 2 + index * 24} fill="#000" fontSize={16} key={index}>
        {line}
      </text>
    ));
  }
  function renderTextLeft(x, y, text) {
    const maxCharPerLine = maxCharPerLineWrapper;
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (let word of words) {
      if ((currentLine + word).length <= maxCharPerLine) {
        currentLine += word + " ";
      } else {
        lines.push(currentLine.trim()); // trim to remove trailing space
        currentLine = word + " ";
      }
    }
    if (currentLine) lines.push(currentLine.trim()); // Add last line if there's any content left

    return lines.map((line, index) => (
      <text
        x={x - line.length * 6.1}
        y={y + index * 24}
        fill="#000"
        fontSize={16}
        key={index}
      >
        {line}
      </text>
    ));
  }

  return (
    <div style={{ position: "relative" }}>
      <PiechartWithLines result={result} scoreColor={scoreColor} />
      <svg
        width="1070"
        height="630"
        viewBox="-20 0 1070 630"
        style={{ position: "absolute", top: "-155px", left: "-375px" }}
      >
        {result.map((segment, index) => {
          const segmentLength = 360 * (Math.abs(segment.value) / total);
          const endAngle = startAngle + segmentLength;
          const midAngle = (startAngle + endAngle) / 2;

          const segmentColor = segment.value < 0 ? "#FF5D1B" : "#A2DD67";
          var oldStartAngle = startAngle;
          startAngle = endAngle;

          if (midAngle >= 0 && midAngle <= 60) {
            // top right
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;

            var flagFirst = 0;
            var flagNextCentreRight = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (nextSegment) {
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);

              if (!previousSegment && Math.abs(nextYStart - yStart) < 35) {
                flagFirst = 1;
              }

              if (previousSegment) {
                let prevMidAngle = previousSegment["midAngle"];
                let prevYStart = calculateYStart(prevMidAngle);

                if (
                  Math.abs(prevYStart - yStart) < 35 &&
                  nextMidAngle > 60 &&
                  nextMidAngle <= 120
                ) {
                  flagNextCentreRight = 1;
                }
              }
            }

            var text = segment.label;

            var text = segment.label;
            return (
              <React.Fragment>
                <text
                  x={xStart + lineLength + 186 + 7}
                  y={
                    yStart - 32 + 50 - flagFirst * 23 + flagNextCentreRight * 14
                  }
                  fill={segmentColor}
                  fontSize={24}
                >
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}
                </text>
                {renderText(
                  xStart + lineLength + 186,
                  yStart - 10 + 50 - flagFirst * 23 + flagNextCentreRight * 11,
                  text
                )}
              </React.Fragment>
            );
          }

          if (midAngle >= 300 && midAngle <= 360) {
            //top left
            let xStart = offsetX + 3 + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 15 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            var flagLast = 0;
            var flagLastCentreLeft = 0;
            var flagLastClose = 0;
            var flagMoveDownNextClose = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment) {
              let prevMidAngle = previousSegment["midAngle"];
              let prevYStart = calculateYStart(prevMidAngle);

              if (!nextSegment && Math.abs(prevYStart - yStart) < 35) {
                flagLast = 1;
              }

              if (nextSegment) {
                let nextMidAngle = nextSegment["midAngle"];
                let nextYStart = calculateYStart(nextMidAngle);

                if (
                  prevMidAngle > 240 &&
                  prevMidAngle < 300 &&
                  Math.abs(nextYStart - yStart) < 35
                ) {
                  flagLastCentreLeft = 1;
                }
                let nextNextSegment = result[index + 2];
                if (!nextNextSegment && Math.abs(prevYStart - yStart) < 35) {
                  flagLastClose = 1;
                }

                let prevPrevSegment = result[index - 2];

                let prevPrevMidAngle = prevPrevSegment["midAngle"];
                if (
                  prevPrevSegment &&
                  Math.abs(nextYStart - yStart) < 35 &&
                  prevPrevMidAngle > 240 &&
                  prevPrevMidAngle < 300
                ) {
                  flagMoveDownNextClose = 1;
                }
              }
            }
            return (
              <React.Fragment>
                <text
                  x={xStart - lineLength + 180 - 16}
                  y={
                    yStart -
                    30 +
                    50 -
                    flagLast * 35 +
                    flagLastCentreLeft * 24 -
                    flagLastClose * 2 +
                    flagMoveDownNextClose * !flagLastCentreLeft * 14
                  }
                  fill={segmentColor}
                  fontSize={24}
                >
                  {" "}
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}{" "}
                </text>
                {renderTextLeft(
                  xStart - lineLength + 160 - 12,
                  yStart -
                    10 +
                    50 -
                    flagLast * 34 +
                    flagLastCentreLeft * 21 -
                    flagLastClose * 6 +
                    flagMoveDownNextClose * !flagLastCentreLeft * 10,
                  segment.label
                )}
              </React.Fragment>
            );
          }

          if (midAngle > 60 && midAngle < 120) {
            //centre right
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;
            var text = segment.label;
            return (
              <React.Fragment>
                <text
                  x={xStart + lineLength + 185 + 7}
                  y={yStart - 6 + 50 - 5}
                  fill={segmentColor}
                  fontSize={24}
                >
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}
                </text>
                {renderText(
                  xStart + lineLength + 185,
                  yStart + 19 + 50 - 9,
                  text
                )}
              </React.Fragment>
            );
          }

          if (midAngle > 240 && midAngle < 300) {
            //centre left
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            return (
              <React.Fragment>
                <text
                  x={xStart - lineLength + 155}
                  y={yStart + 49}
                  fill={segmentColor}
                  fontSize={24}
                >
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}
                </text>
                {renderTextLeft(
                  xStart - lineLength + 145,
                  yStart + 14 + 55,
                  segment.label
                )}
              </React.Fragment>
            );
          }

          if (midAngle >= 120 && midAngle < 180) {
            //bottom right
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = maxXEndRight - xStart;

            var flagPrevCentreRight = 0;
            var flagPrevBottomRightClose = 0;
            var flagNextBottomLeft = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment && nextSegment) {
              let previousMidAngle = previousSegment["midAngle"];
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);
              let prevYStart = calculateYStart(previousMidAngle);

              if (
                previousMidAngle >= 60 &&
                previousMidAngle < 120 &&
                nextMidAngle >= 120 &&
                nextMidAngle < 180
              ) {
                flagPrevCentreRight = 1;
              }

              if (
                !(previousMidAngle >= 60 && previousMidAngle < 120) &&
                Math.abs(nextYStart - yStart) < 20
              ) {
                flagPrevBottomRightClose = 1;
              }

              if (
                nextMidAngle >= 180 &&
                nextMidAngle <= 240 &&
                Math.abs(prevYStart - yStart) < 20
              ) {
                flagNextBottomLeft = 1;
              }
            }
            var text = segment.label;

            return (
              <React.Fragment>
                <text
                  x={xStart + lineLength + 185}
                  y={
                    yStart +
                    54 -
                    flagPrevCentreRight * 12 +
                    flagPrevBottomRightClose * 10 +
                    flagNextBottomLeft * 35
                  }
                  fill={segmentColor}
                  fontSize={24}
                >
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}
                </text>

                {renderText(
                  xStart + lineLength + 185,
                  yStart +
                    76 -
                    flagPrevCentreRight * 12 +
                    flagPrevBottomRightClose * 10 +
                    flagNextBottomLeft * 35,

                  text
                )}
              </React.Fragment>
            );
          }

          if (midAngle >= 180 && midAngle <= 240) {
            //bottom left
            let xStart = offsetX + outerRadius * Math.sin(midAngle * rad);
            let yStart = offsetY - 13 - outerRadius * Math.cos(midAngle * rad);
            let lineLength = xStart - minXEndLeft;

            var flagPrevBottomRight = 0;
            var flagNextCentreLeft = 0;
            var flagPrevPrevCentreRightNextClose = 0;

            let previousSegment = result[index - 1];
            let nextSegment = result[index + 1];
            if (previousSegment && nextSegment) {
              let previousMidAngle = previousSegment["midAngle"];
              let nextMidAngle = nextSegment["midAngle"];
              let nextYStart = calculateYStart(nextMidAngle);
              let prevYStart = calculateYStart(previousMidAngle);

              if (
                previousMidAngle >= 120 &&
                previousMidAngle < 180 &&
                Math.abs(nextYStart - yStart) < 35
              ) {
                flagPrevBottomRight = 1;
              }
              if (
                nextMidAngle > 240 &&
                nextMidAngle <= 300 &&
                Math.abs(prevYStart - yStart) < 40
              ) {
                flagNextCentreLeft = 1;
              }
              let prevPrevSegment = result[index - 2];
              if (
                prevPrevSegment &&
                prevPrevSegment["midAngle"] >= 120 &&
                prevPrevSegment["midAngle"] < 180 &&
                previousMidAngle >= 180 &&
                previousMidAngle < 240 &&
                Math.abs(nextYStart - yStart) < 35
              ) {
                flagPrevPrevCentreRightNextClose = 1;
              }
            }

            return (
              <React.Fragment>
                <text
                  x={xStart - lineLength + 156}
                  y={
                    yStart +
                    67 +
                    flagPrevBottomRight * 34 -
                    flagNextCentreLeft * 10 +
                    flagPrevPrevCentreRightNextClose * 8
                  }
                  fill={segmentColor}
                  fontSize={24}
                >
                  {segment.value > 0 ? "+" : ""}
                  {segment.value}
                </text>
                {renderTextLeft(
                  xStart - lineLength + 142,
                  yStart +
                    84 +
                    flagPrevBottomRight * 38 -
                    flagNextCentreLeft * 8 +
                    flagPrevPrevCentreRightNextClose * 12,
                  segment.label
                )}
              </React.Fragment>
            );
          }
        })}
      </svg>
    </div>
  );
};
export default EnhancedPiechartWithLines;
