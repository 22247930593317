import { useEffect, useState } from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import pdfLogo from '../../../assets/iconPDF.png';
import { theme } from "../../../styles/Theme";

const classes = {
    fileContainer: {
        display: 'flex',
        alignItems:'center',
        borderRadius: '.5rem',
        transition: 'transform ease 0.5s, box-shadow ease 0.5s',
        padding: '5px',
        '&:hover': {
            boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
        }
    },
    verticalAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    linearProgressContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeIconStyling: {
        padding: '4px',
        borderRadius: "100px",
        backgroundColor: 'rgba(120, 143, 223, 0.2)',
        cursor: 'pointer',
        fill: `${theme.palette.primary.dark}`,
        '&:hover': {
            fill: `${theme.palette.primary.dark}`,
            backgroundColor: 'rgba(153, 51, 51, 0.2)',
        }
    },
    fileSize: {
        fontSize: '.7rem',
        color: `${theme.palette.secondary.main}`
    }
}

const File = ({setLoadingFileAnimationDone, file_id, fileName, removeFile, fileSize}) => {
    const [progress, setProgress] = useState(0);
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    useEffect(() => {
        progress !== 100 && setLoadingFileAnimationDone(false)
        progress === 100 && setLoadingFileAnimationDone(true)
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
        }, 300);
        return () => {
            clearInterval(timer);
        };
    }, [progress, setLoadingFileAnimationDone]);

    const calcSize = (size) => {
        return size < 1000000 ? `${Math.floor(size / 1000)} KB` : `${Math.floor(size / 1000000)} MB`;
    };

    return (
        <Grid container sx={classes.fileContainer} justifyContent='space-between' alignItems='center' onMouseEnter={e => setShowDeleteButton(true)} onMouseLeave={e => setShowDeleteButton(false)}>
            <Grid item sx={classes.verticalAlign}>
                <img src={pdfLogo} alt='pdf-icon' />
            </Grid>
            <Grid item xs={9} sx={classes.verticalAlign}>
                <Grid container direction='column'>
                    <Grid item sx={classes.linearProgressContainer}>
                        <Typography>{fileName}</Typography>
                        {progress !== 100 && <Typography>{progress} %</Typography>}
                    </Grid>
                    {progress !== 100 && <LinearProgress  variant="determinate" value={progress}/>}
                    {progress === 100 && <Typography sx={classes.fileSize}>{calcSize(fileSize)}</Typography>}
                </Grid>                    
            </Grid>
            <Grid
                item
                sx={classes.verticalAlign}
            >
                {progress === 100 && showDeleteButton && <CloseIcon sx={classes.closeIconStyling} onClick={()=> removeFile(file_id)} /> }
                {progress === 100 && !showDeleteButton && <CheckIcon sx={{color:'#469b62', cursor: 'pointer'}} />}
                {progress !== 100 && <CloseIcon sx={classes.closeIconStyling} onClick={()=> removeFile(file_id)} />}
            </Grid>
        </Grid>
    )
}

export default File